/* Component to display the number, title and help text for a question, and ensure appropriate padding */
import { SectionHeader } from '@Components';
import { buildQuestion } from '@Components/Surveys/utils';
import { QuestionConfig } from '@Types';
import styles from './QuestionWrapper.module.scss';
import { Tile } from '@Components/common';
import { RiInformationFill } from 'react-icons/ri';

export interface QuestionWrapperProps {
  questionNumber: number;
  questionConfig: QuestionConfig;
}

export const QuestionWrapper = ({ questionConfig, questionNumber }: QuestionWrapperProps) => {
  const question = buildQuestion(questionConfig);
  const label = `${questionNumber}. ${questionConfig.label}`;
  return (
    <Tile className={styles.questionWrapper}>
      <>
        <SectionHeader title={label} subtitle={questionConfig.helpText} />
        {questionConfig.infoText && (
          <div className={styles.infoTextContainer}>
            <RiInformationFill size={24} />
            {questionConfig.infoText}
          </div>
        )}
        {question}
      </>
    </Tile>
  );
};
