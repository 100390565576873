import { CheckboxGroup } from '@Components/common';
import { QuestionChoice } from '@Types';

export interface MultipleChoiceQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  choices: QuestionChoice[];
  typeIdentifier: string;
  disabled?: boolean;
}

export const MultipleChoiceQuestion = ({
  identifier,
  required,
  choices,
  disabled,
}: MultipleChoiceQuestionProps) => {
  const items = choices.map((choice) => {
    return {
      label: choice.name,
      value: choice.value,
    };
  });

  return (
    <CheckboxGroup
      name={identifier}
      label={''}
      items={items}
      required={required}
      disabled={disabled}
    />
  );
};
