import { useTranslation } from 'react-i18next';
import styles from './FormProgressIndicator.module.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '../Button/Button';
import { RiCheckFill, RiCheckboxBlankCircleFill, RiCheckboxCircleFill } from 'react-icons/ri';
import classnames from 'classnames';
export interface FormProgressIndicatorProps {
  questionCount: number;
  completedQuestions: number;
  errorCount: number;
  saveAsDraft: () => void;
  isSaving: boolean;
  sectionInfo: { label: string; complete: boolean }[];
}

export const FormProgressIndicator = ({
  questionCount,
  completedQuestions,
  errorCount,
  saveAsDraft,
  isSaving,
  sectionInfo,
}: FormProgressIndicatorProps) => {
  const { t } = useTranslation('common');
  const progressPercentage = Math.min((completedQuestions / (questionCount || 1)) * 100, 100);
  const errorCountMessage = t('error_count', { count: errorCount });
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p>{t('progress')}</p>
        <p>{t('percentage_whole', { percentage: progressPercentage })}</p>
      </div>
      <LinearProgress
        variant="determinate"
        value={progressPercentage}
        className={styles.completionBar}
      />
      <div className={styles.sectionInfoWrapper}>
        {sectionInfo.map(({ label, complete }, i) => {
          return (
            <div className={styles.sectionInfoItem}>
              {complete ? (
                <RiCheckboxCircleFill className={styles.complete} />
              ) : (
                <RiCheckboxBlankCircleFill className={styles.sectionInfoCheck} />
              )}
              <p className={classnames(styles.sectionInfoLabel, complete && styles.complete)}>
                {label}
              </p>
            </div>
          );
        })}
      </div>
      <Button
        onClick={saveAsDraft}
        buttonType="tertiary"
        disabled={isSaving}
        className={styles.saveDraftButton}
      >
        {t('save_changes')}
      </Button>
      {errorCount > 0 && <div className={styles.errorText}>{errorCountMessage}</div>}
    </div>
  );
};
