import { Fragment } from 'react';
import { SectionHeader } from '@Components';
import styles from './SurveySection.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { SurveySectionTitle } from './SurveySectionTitle';

export interface SurveySectionProps {
  label: string;
  heading: string;
  children: JSX.Element[];
  sectionNumber: number;
  sectionCount: number;
}

export const SurveySection = ({
  label,
  heading,
  children,
  sectionNumber,
  sectionCount,
}: SurveySectionProps) => {
  let title;
  const { t } = useTranslation('surveys');

  if (sectionNumber === 0) {
    // The first section gets custom title display below, the rest can use the reusable component
    title = (
      <div className={styles.surveyHeader}>
        <h2 className={styles.header}>{label}</h2>
        <h2 className={styles.subtitle}>{t('impact_report')}</h2>
        <div className={styles.intro}>
          <Trans ns="surveys" i18nKey="impact_report_intro" components={[<p />]} />
        </div>
        <img
          className={styles.afLogo}
          src={'/assets/Logo/primaryLogo.svg'}
          alt="ActionFunder logo"
        />
      </div>
    );
  } else {
    let fullLabel = `Standard survey - ${label}`;
    if (sectionNumber > 2) {
      fullLabel = `Additional questions - ${label}`;
    }

    title = <SurveySectionTitle title={fullLabel} subtitle={heading} />;
  }
  return (
    <Fragment>
      {title}
      {children}
    </Fragment>
  );
};
