import { camelCase, isArray, snakeCase, transform, isObject } from 'lodash';

export const camelCaseObject = (obj: Record<string, unknown>) =>
  transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    /* Turn an object with snake_case keys into camelCase, and all nested objects, for example to tidy up snake_case data from the backend. */
    let camelCaseKey = key;
    if (!isArray(target)) {
      camelCaseKey = camelCase(key);
    }
    let camelCaseValue = value;
    if (isObject(value)) {
      camelCaseValue = camelCaseObject(value as Record<string, unknown>);
    }
    result[camelCaseKey] = camelCaseValue;
  });
