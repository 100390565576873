import React from 'react';
import styles from './SurveySectionTitle.module.scss';
import classnames from 'classnames';

interface SurveySectionTitleProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  className?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
  imgURL?: string;
  imgAlt?: string;
}

export const SurveySectionTitle = ({
  title,
  subtitle,
  className,
  scrollRef,
  imgURL,
  imgAlt,
}: SurveySectionTitleProps) => {
  return (
    <div className={classnames(styles.wrapper, className)} ref={scrollRef}>
      {imgURL && <img src={imgURL} alt={imgAlt} />}
      {title && <h3 className={styles.sectionTitle}>{title}</h3>}

      {subtitle && <p className={styles.sectionSubtitle}>{subtitle}</p>}
    </div>
  );
};
