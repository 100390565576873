/* A question that collects a set of statements like:
30 apples cored
10 oranges zested
15 bananas peeled
in a structured format, and handles them via JSON.
*/
import { ChangeEvent, forwardRef, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl as MaterialFormControl,
  FormHelperText as MaterialFormHelperText,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from '@Components/common/TextInput/TextInput';
import { msgFieldRequired } from '@Helpers/errorMessages';
import styles from './OutputQuestion.module.scss';

interface Output {
  number: number;
  description: string;
}

interface OutputValues {
  outputs: Output[];
}

export interface OutputQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  children: JSX.Element[];
  disabled?: boolean;
}

export interface OutputRowProps {
  identifier: string;
  index: number;
  value: Output;
  onChange: any;
  disabled?: boolean;
}

const copyOutputValues = (outputValues: OutputValues): OutputValues => {
  return {
    outputs: outputValues.outputs.map((row: Output) => {
      return { ...row };
    }),
  };
};

const stripEmptyRows = (outputValues: OutputValues): OutputValues => {
  // Remove empty rows from our result data so we don't post them to the server.
  return {
    outputs: outputValues.outputs.filter((output) => {
      return !!output.number;
    }),
  };
};

export const OutputRow = forwardRef(
  ({ identifier, index, value, onChange, disabled }: OutputRowProps, ref) => {
    const { t: tSurveys } = useTranslation('surveys');
    let resultPreview = '';
    let number = undefined;
    let description = '';
    if (value) {
      resultPreview = `${value.number} ${value.description}`;
      number = value.number;
      description = value.description;
    }
    const numberLabel = `${tSurveys('number')} ${index + 1}`;
    const descriptionLabel = `${tSurveys('description')} ${index + 1}`;
    // Put the ref on the first item of the first row, so react-hook-form scrolls to the top when there's errors.
    return (
      <Fragment>
        <div>
          <TextInput
            type="number"
            label={numberLabel}
            name={`${identifier}_${index}_number`}
            onChange={(event) => {
              onChange('number', index, event);
            }}
            value={number}
            inputRef={index === 0 ? ref : undefined}
            disabled={disabled}
          />
        </div>
        <div>
          <TextInput
            type="text"
            label={descriptionLabel}
            name={`${identifier}_${index}_description`}
            onChange={(event) => {
              onChange('description', index, event);
            }}
            value={description}
            disabled={disabled}
          />
        </div>
        <div style={{ padding: 15 }}>{resultPreview}</div>
      </Fragment>
    );
  },
);

OutputRow.displayName = 'OutputRow';

export const OutputQuestion = ({
  identifier,
  required,
  children,
  disabled,
}: OutputQuestionProps) => {
  const { t: tSurveys } = useTranslation('surveys');

  const { control, setValue, watch, errors: fieldErrors } = useFormContext();

  const initialState: OutputValues = watch(identifier) || { outputs: [] };
  const [result, setResult] = useState(initialState);

  const valueChange = (
    field: keyof Output,
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newResult = copyOutputValues(result);
    const value = event.target.value;
    if (newResult.outputs[index]) {
      // @ts-ignore - typescript makes this kind of indexing quite painful.
      newResult.outputs[index][field] = value;
    } else {
      const newRow: Output = {
        number: 0,
        description: '',
      };
      // @ts-ignore - typescript makes this kind of indexing quite painful.
      newRow[field] = value;
      newResult.outputs.push(newRow);
    }
    setResult(newResult);
    setValue(identifier, stripEmptyRows(newResult));
  };

  const getValue = (index: number) => {
    return result.outputs[index];
  };

  let validate = undefined;
  if (required) {
    validate = (value: OutputValues | undefined) => {
      if (!value || value.outputs.length === 0) {
        return msgFieldRequired;
      } else {
        return true;
      }
    };
  }

  const error = fieldErrors[identifier];
  Array(result.outputs.length);
  const rowIndices = Array.from(result.outputs.keys());
  // Ensure we've always got an extra blank row
  rowIndices.push(result.outputs.length);
  return (
    <MaterialFormControl error={!!error} fullWidth>
      {children}
      <Controller
        name={identifier}
        control={control}
        rules={{ validate }}
        defaultValue={null}
        render={({ ref, onChange, value }) => (
          // Just put the ref on the top here, so we scroll to it when there's errors.
          <div className={styles.grid}>
            <div className={styles.gridHeading}></div>
            <div className={styles.gridHeading}></div>
            <div className={styles.gridHeading}>{tSurveys('preview')}</div>
            {rowIndices.map((index) => (
              <OutputRow
                key={index}
                index={index}
                identifier={identifier}
                value={getValue(index)}
                onChange={valueChange}
                ref={index === 0 ? ref : undefined}
                disabled={disabled}
              />
            ))}
          </div>
        )}
      />
      <MaterialFormHelperText>{error?.message}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};
