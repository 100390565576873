import { Fragment } from 'react';
import { buildQuestion } from '@Components/Surveys/utils';
import { QuestionConfig } from '@Types';

export interface CompoundQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  subquestions: QuestionConfig[];
  disabled?: boolean;
}

export const CompoundQuestion = ({ subquestions, disabled }: CompoundQuestionProps) => {
  if (disabled) {
    for (const subquestion of subquestions) {
      subquestion.disabled = disabled;
    }
  }
  const children = subquestions.map(buildQuestion);
  return <Fragment>{children}</Fragment>;
};
