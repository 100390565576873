import { isArray } from 'lodash';
import { createElement } from 'react';
import { BooleanQuestion } from '@Components/Surveys/QuestionTypes/BooleanQuestion/BooleanQuestion';
import { MultipleChoiceQuestion } from '@Components/Surveys/QuestionTypes/MultipleChoiceQuestion/MultipleChoiceQuestion';
import { PositiveIntegerQuestion } from '@Components/Surveys/QuestionTypes/PositiveIntegerQuestion/PositiveIntegerQuestion';
import { SingleChoiceQuestion } from '@Components/Surveys/QuestionTypes/SingleChoiceQuestion/SingleChoiceQuestion';
import { TextAreaQuestion } from '@Components/Surveys/QuestionTypes/TextAreaQuestion/TextAreaQuestion';
import { TextLineQuestion } from '@Components/Surveys/QuestionTypes/TextLineQuestion/TextLineQuestion';
import { NumberChoiceQuestion } from '@Components/Surveys/QuestionTypes/NumberChoiceQuestion/NumberChoiceQuestion';
import { OtherQuestion } from '@Components/Surveys/QuestionTypes/OtherQuestion/OtherQuestion';
import { OutputQuestion } from '@Components/Surveys/QuestionTypes/OutputQuestion/OutputQuestion';
import { StarRatingQuestion } from '@Components/Surveys/QuestionTypes/StarRatingQuestion/StarRatingQuestion';
import { MatrixQuestion } from './QuestionTypes/MatrixQuestion/MatrixQuestion';
import { CompoundQuestion } from './QuestionTypes/CompoundQuestion/CompoundQuestion';
import { MultipleNumbersQuestion } from './QuestionTypes/MultipleNumbersQuestion/MultipleNumbersQuestion';

export const buildQuestion = (questionConfig: any) => {
  // @ts-ignore - we can rely on this working if the backend is working correctly,
  // and should raise errors at runtime if an unexpected error shows up.
  const QUESTION_TYPE_MAPPING: Record<string, any> = {
    BOOLEAN: BooleanQuestion,
    CHOICE_OTHER: OtherQuestion,
    COMPOUND: CompoundQuestion,
    MULTIPLE_CHOICE: MultipleChoiceQuestion,
    POSITIVE_INTEGER: PositiveIntegerQuestion,
    SINGLE_CHOICE: SingleChoiceQuestion,
    TEXT_AREA: TextAreaQuestion,
    TEXT_LINE: TextLineQuestion,
    MATRIX: MatrixQuestion,
    NUMBER_CHOICE_RATING: NumberChoiceQuestion,
    OUTPUTS: OutputQuestion,
    STARS_RATING: StarRatingQuestion,
    MULTIPLE_NUMBERS: MultipleNumbersQuestion,
  };
  const questionType = QUESTION_TYPE_MAPPING[questionConfig.typeIdentifier];
  if (!questionType) {
    throw new Error(`Unexpected question type ${questionConfig.typeIdentifier} found`);
  }
  return createElement(questionType, { ...questionConfig, key: questionConfig.identifier }, null);
};

export const surveyValueIsEmpty = (value: any) => {
  return value == null || value === '' || (isArray(value) && value.length === 0);
};

export const nonEmptySurveyData = (data: any) => {
  return Object.fromEntries(
    Object.entries(data).filter((entry) => {
      const [name, value] = entry;
      return !surveyValueIsEmpty(value);
    }),
  );
};
