import { useFormContext } from 'react-hook-form';

import { TextArea } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';

export interface TextAreaQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  shortLabel?: string;
  disabled?: boolean;
}

export const TextAreaQuestion = ({
  identifier,
  required,
  shortLabel,
  disabled,
}: TextAreaQuestionProps) => {
  const { errors: fieldErrors, register } = useFormContext();

  return (
    <TextArea
      label={shortLabel}
      type="text"
      rows="3"
      name={identifier}
      inputRef={required ? register({ required: msgFieldRequired }) : register()}
      error={!!fieldErrors[identifier]}
      helperText={fieldErrors[identifier]?.message}
      disabled={disabled}
    />
  );
};
