import { RadioGroup } from '@Components/common';
import { msgChooseOption } from '@Helpers/errorMessages';

export interface BooleanQuestionProps {
  identifier: string;
  typeIdentifier: string;
  label: string;
  required: boolean;
  disabled?: boolean;
}

export const BooleanQuestion = ({
  identifier,
  label,
  required,
  disabled,
}: BooleanQuestionProps) => {
  const items = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  const valueFromString = (value: any) => {
    if (value == null || value === '') {
      return null;
    }
    return value === 'true';
  };

  const valueToString = (value: string) => {
    if (value == null || value === '') {
      return '';
    }
    return value ? 'true' : 'false';
  };

  let validate = undefined;
  if (required) {
    validate = (value: boolean | null) => {
      if (typeof value != 'boolean') {
        return msgChooseOption;
      } else {
        return true;
      }
    };
  }

  return (
    <RadioGroup
      name={identifier}
      label={''}
      items={items}
      required={false}
      validate={validate}
      valueToString={valueToString}
      valueFromString={valueFromString}
      disabled={disabled}
      row
    />
  );
};
