import { useFormContext } from 'react-hook-form';

import { TextInput } from '@Components/common';
import { msgFieldRequired, msgFieldInvalid } from '@Helpers/errorMessages';

export interface PositiveIntegerQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  shortLabel?: string;
  disabled?: boolean;
}

export const PositiveIntegerQuestion = ({
  identifier,
  required,
  shortLabel,
  disabled,
}: PositiveIntegerQuestionProps) => {
  const { errors: fieldErrors, register } = useFormContext();

  const inputConfig: {
    required?: string;
    pattern: any;
  } = {
    required: undefined,
    pattern: {
      value: /^\d*$/,
      message: msgFieldInvalid,
    },
  };
  if (required) {
    inputConfig.required = msgFieldRequired;
  }

  return (
    <TextInput
      label={shortLabel}
      type="number"
      name={identifier}
      inputRef={register(inputConfig)}
      error={!!fieldErrors[identifier]}
      helperText={fieldErrors[identifier]?.message}
      disabled={disabled}
    />
  );
};
