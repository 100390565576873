import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { buildQuestion } from '@Components/Surveys/utils';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { QuestionChoice, QuestionConfig } from '@Types';

export interface OtherQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  subquestions: QuestionConfig[];
  choices: QuestionChoice[];
  disabled?: boolean;
}

export const OtherQuestion = ({ subquestions, required, disabled }: OtherQuestionProps) => {
  const methods = useFormContext();
  const mainQuestionConfig = {
    ...subquestions[0],
    required,
    disabled: disabled,
  };
  const mainQuestion = buildQuestion(mainQuestionConfig);
  const otherChoiceValidator = (value: string) => {
    const currentMainQuestionValue = methods.getValues(mainQuestionConfig.identifier);
    const required = currentMainQuestionValue && currentMainQuestionValue.includes('other');
    if (required && !value) {
      return msgFieldRequired;
    } else {
      return true;
    }
  };
  const otherChoiceQuestionConfig = {
    ...subquestions[1],
    validate: otherChoiceValidator,
    required: false, // We control the required-ness based on the validator above.
    disabled: disabled,
  };
  const otherChoiceQuestion = buildQuestion(otherChoiceQuestionConfig);
  return (
    <Fragment>
      {mainQuestion}
      {otherChoiceQuestion}
    </Fragment>
  );
};
