import { TileSelect } from '@Components/common/TileSelect/TileSelect';
import { QuestionChoice } from '@Types';

export interface NumberChoiceQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  choices: QuestionChoice[];
  typeIdentifier: string;
  disabled?: boolean;
}

export const NumberChoiceQuestion = ({
  identifier,
  required,
  choices,
  disabled,
}: NumberChoiceQuestionProps) => {
  const items = choices.map((choice) => {
    return {
      label: choice.name,
      value: choice.value,
    };
  });

  return (
    <TileSelect
      name={identifier}
      label={''}
      choices={items}
      required={required}
      leftLabel="Not at all likely"
      rightLabel="Extremely likely"
      disabled={disabled}
    />
  );
};
