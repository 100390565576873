import { useFormContext } from 'react-hook-form';

import { TextInput } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';

export interface TextLineQuestionProps {
  identifier: string;
  shortLabel?: string;
  label: string;
  typeIdentifier: string;
  required: boolean;
  validate?: (value: string) => boolean;
  disabled?: boolean;
}

export const TextLineQuestion = ({
  identifier,
  shortLabel,
  required,
  validate,
  disabled,
}: TextLineQuestionProps) => {
  const { errors: fieldErrors, register } = useFormContext();
  const validators = {
    required: required ? msgFieldRequired : undefined,
    validate: validate,
  };

  return (
    <TextInput
      label={shortLabel}
      type="text"
      name={identifier}
      inputRef={register(validators)}
      error={!!fieldErrors[identifier]}
      helperText={fieldErrors[identifier]?.message}
      disabled={disabled}
    />
  );
};
