// A field where values are selected by clicking on one of several tiles.
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl as MaterialFormControl,
  FormHelperText as MaterialFormHelperText,
  FormLabel as MaterialFormLabel,
} from '@material-ui/core';
import { CheckboxTile } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';

export interface TileSelectItem {
  value: string;
  label: string;
  icon?: JSX.Element;
}

export interface TileSelectProps {
  name: string;
  label: string;
  required: boolean;
  choices: TileSelectItem[];
  leftLabel?: string;
  rightLabel?: string;
  disabled?: boolean;
}

export const TileSelect = ({
  name,
  label,
  required,
  choices,
  leftLabel,
  rightLabel,
  disabled,
}: TileSelectProps) => {
  const { errors: fieldErrors, control } = useFormContext();
  const gridTemplateColumns = `repeat(${choices.length}, 1fr)`;
  return (
    <MaterialFormControl error={!!fieldErrors[name]} fullWidth>
      {label && <MaterialFormLabel>{label}</MaterialFormLabel>}

      {leftLabel && rightLabel && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            marginLeft: '10',
            alignContent: 'space-between',
          }}
        >
          <div>{leftLabel}</div>
          <div style={{ textAlign: 'right' }}>{rightLabel}</div>
        </div>
      )}
      <Controller
        name={name}
        rules={{ required: required && msgFieldRequired }}
        control={control}
        defaultValue={null}
        render={({ onChange, value, ref }) => (
          <div
            style={{ display: 'grid', gridTemplateColumns: gridTemplateColumns, marginLeft: '10' }}
          >
            {choices.map((choice) => (
              <CheckboxTile
                key={choice.value}
                checked={value == choice.value}
                error={false} // Errors aren't tied to individual tiles here.
                value={choice.value}
                icon={choice.icon}
                name={name}
                label={choice.label}
                onChange={onChange}
                inputRef={ref}
                disabled={disabled}
              />
            ))}
          </div>
        )}
      />
      <MaterialFormHelperText>{fieldErrors[name]?.message}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};
