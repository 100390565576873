import { Fragment } from 'react';
import StarIcon from '@material-ui/icons/Star';
import { QuestionChoice } from '@Types';
import { TileSelect, TileSelectItem } from '@Components/common/TileSelect/TileSelect';

export interface StarRatingQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  choices: QuestionChoice[];
  disabled?: boolean;
}

interface StarIconSetProps {
  starCount: number;
}

const StarIconSet = ({ starCount }: StarIconSetProps) => {
  return (
    <Fragment>
      {Array(starCount)
        .fill(null)
        .map((_, index) => {
          return <StarIcon key={index} />;
        })}
    </Fragment>
  );
};

export const StarRatingQuestion = ({
  identifier,
  required,
  choices,
  disabled,
}: StarRatingQuestionProps) => {
  const choicesList = choices.map((choice) => {
    const result: TileSelectItem = {
      label: choice.name,
      value: choice.value,
    };
    const starCount = parseInt(choice.value, 10);
    if (starCount) {
      result.icon = StarIconSet({ starCount });
    }
    return result;
  });

  return (
    <TileSelect
      name={identifier}
      label={''}
      choices={choicesList}
      required={required}
      disabled={disabled}
    />
  );
};
