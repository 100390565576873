import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { QuestionChoice, QuestionConfig } from '@Types';
import {
  FormControl as MaterialFormControl,
  FormHelperText as MaterialFormHelperText,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { msgFieldRequired } from '@Helpers/errorMessages';

export interface MatrixQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  subquestions: QuestionConfig[];
  choices: QuestionChoice[];
  disabled?: boolean;
}

export const MatrixQuestion = ({ label, subquestions, choices, disabled }: MatrixQuestionProps) => {
  const { control, errors: fieldErrors } = useFormContext();
  // If any field has an error, just pick one at random and show that.
  let error: any;
  subquestions.forEach((subquestion) => {
    const questionError = fieldErrors[subquestion.identifier];
    if (questionError) {
      error = questionError;
    }
  });
  return (
    <MaterialFormControl error={!!error} fullWidth>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {choices.map((choice) => (
              <TableCell key={choice.value} align="center">
                {choice.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {subquestions.map((subquestion, index) => (
            <TableRow key={subquestion.identifier}>
              <TableCell align="left">{subquestion.label}</TableCell>
              <Controller
                name={subquestion.identifier}
                control={control}
                defaultValue={null}
                rules={{ required: subquestion.required && msgFieldRequired }}
                render={({ ref, onChange, value }) => (
                  <Fragment>
                    {subquestion.choices!.map((choice) => (
                      <TableCell key={choice.value} align="center">
                        <Radio
                          checked={value == choice.value}
                          onChange={onChange}
                          value={choice.value}
                          name={subquestion.identifier}
                          inputProps={{ 'aria-label': subquestion.label }}
                          color="primary"
                          disabled={disabled}
                        />
                      </TableCell>
                    ))}
                  </Fragment>
                )}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <MaterialFormHelperText>{error?.message}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};
