/*
Progress indicator for the survey form.
This is located in a separate component because it needs to re-render regularly - any time a question is changed.
*/
import { isEmpty } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormProgressIndicator } from '@Components/common/FormProgressIndicator/FormProgressIndicator';
import { QuestionConfig, SurveyConfiguration } from '@Types';
import { nonEmptySurveyData } from '@Components/Surveys/utils';

export interface SurveyProgressIndicatorProps {
  surveyConfig: SurveyConfiguration;
  saveAsDraft: () => void;
  isSaving: boolean;
}

const getProgressRelatedQuestionNames = (questionConfig: QuestionConfig) => {
  if (!questionConfig.required) {
    // Optional questions aren't counted towards progress.
    return [];
  } else if (questionConfig.typeIdentifier == 'MATRIX') {
    // All matrix question entries count towards progress.
    return questionConfig.subquestions!.map((subquestionConfig) => subquestionConfig.identifier);
  } else if (['CHOICE_OTHER', 'COMPOUND'].includes(questionConfig.typeIdentifier)) {
    // For other compound questions, just count the first item, as that's always required.
    return [questionConfig.subquestions![0].identifier];
  } else {
    // Any non-compound required question counts towards progress.
    return [questionConfig.identifier];
  }
};

export const SurveyProgressIndicator = (props: SurveyProgressIndicatorProps) => {
  const surveyConfig = props.surveyConfig;
  const methods = useFormContext();

  // We only include required questions, and only matrix subquestions, in counting progress.
  const questionsRequiredForCompletion: string[] = [];
  surveyConfig.sections.forEach((sectionConfig) => {
    sectionConfig.questions.forEach((questionConfig) => {
      questionsRequiredForCompletion.push(...getProgressRelatedQuestionNames(questionConfig));
    });
  });
  const errorCount = Object.keys(methods.errors).length;
  // Re-render this component every time the form data changes, so our progress updates.
  useWatch({});
  // react-hook-form doesn't trigger useWatch() when we set the initial form data,
  // so if the data here is empty set it to our initial data.
  const currentFormData = methods.getValues();
  const currentData = isEmpty(currentFormData) ? props.surveyConfig.data || {} : currentFormData;
  const completedQuestions = nonEmptySurveyData(currentData);
  const completedProgressQuestions = Object.entries(completedQuestions).filter((entry) => {
    return questionsRequiredForCompletion.includes(entry[0]);
  });
  let sectionInfo: { label: string; complete: boolean }[] = [];
  surveyConfig.sections.slice(1).forEach((sectionConfig) => {
    // TODO: add logic to determine if all the questions inside sectionConfig.questions are inside completed questions array
    // Which will tell us if the section is complete or not.
    sectionInfo.push({ label: sectionConfig.label, complete: false });
  });

  const completedProgressQuestionsCount = completedProgressQuestions.length;
  const questionCount = questionsRequiredForCompletion.length;
  return (
    <FormProgressIndicator
      questionCount={questionCount}
      completedQuestions={completedProgressQuestionsCount}
      errorCount={errorCount}
      saveAsDraft={props.saveAsDraft}
      isSaving={props.isSaving}
      sectionInfo={sectionInfo}
    />
  );
};
