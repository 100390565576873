import { QuestionConfig } from '@Types';
import { Fragment } from 'react';
import { buildQuestion } from '@Components/Surveys/utils';
import styles from './MultipleNumberQuestion.module.scss';

export interface MultipleNumbersQuestionProps {
  identifier: string;
  label: string;
  required: boolean;
  typeIdentifier: string;
  subquestions: QuestionConfig[];
  disabled?: boolean;
}

export const MultipleNumbersQuestion = ({
  subquestions,
  disabled,
}: MultipleNumbersQuestionProps) => {
  if (disabled) {
    for (const subquestion of subquestions) {
      subquestion.disabled = disabled;
    }
  }
  const children = subquestions.map(buildQuestion);
  return <div className={styles.multipleNumberQuestionContainer}>{children}</div>;
};
